<template>
  <div class="w-full background">
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan lomaketta..."
      class="full-page-loader"
    ></full-page-loader>

    <!-- Form -->
    <v-container v-if="!loading && formIsActive" fluid>
      <form-body class="pt-3 pb-3" :isPreview="false"></form-body>
    </v-container>

    <div v-if="!loading && !formIsActive">
      <v-card align="center"
        ><h2 class="pa-2">Lomake ei ole tällä hetkellä täytettävissä.</h2></v-card
      >
    </div>
  </div>
</template>

<script>
import FormBody from "./FormBody.vue";
import mixins from "@/mixins/mixins";
import FullPageLoader from "@/components/FullPageLoader";
import { mapActions, mapMutations, mapState } from "vuex";
import { loadFont } from "../../utils/helpers";

export default {
  mixins: [mixins],
  title: "Lomake",

  components: {
    FormBody,
    FullPageLoader,
  },

  computed: {
    ...mapState("form", ["loading", "currentForm"]),

    formIsActive() {
      const currentForm = this.$store.state.form.currentForm;

      if (currentForm) {
        // Check current form status
        if (!currentForm.active) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },

  created() {
    this.setLoading(true);
    this.getOnePublic(this.$route.params.id);
  },

  mounted() {
    this.$nextTick(() => {
      loadFont(this.currentForm?.styles?.fontFamily);
    });
  },

  watch: {
    loading(val) {
      if (!val && this.currentForm?.styles?.fontFamily) {
        loadFont(this.currentForm?.styles?.fontFamily);
      }
    },
  },

  methods: {
    ...mapActions("form", ["getOnePublic"]),
    ...mapMutations("form", ["setLoading"]),
  },
};
</script>

<style scoped>
.v-application .background {
  background-color: white !important;
}
.w-full {
  min-height: 100vh;
}
</style>
